import React, { useEffect, useState } from 'react'
import './LiveScoreboard.css'
import { Row, Col } from 'antd'
import { BINGO_FLAGS, SPOLIGHT_TYPE, CUSTOM_THEME, handleImageError } from './util/Utils'
import RandomNumber from './RandomNumber'
import 'antd/dist/antd.min.css'
import { motion } from 'framer-motion'
import './themes-css/CUSTOM_THEME.css'


export default function CustomThemeScoreboard({ customTheme }) {

    const [randomNumber, setRandomNumber] = useState(0)

    useEffect(() => {
        let intervalId
        if (customTheme) {
            intervalId = setInterval(() => {
                setRandomNumber(Math.floor(Math.random() * customTheme.rows * customTheme.cols) + 1)
            }, 250)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
            setRandomNumber(0)
        }
    }, [customTheme])

    let number
    let name
    if (randomNumber && randomNumber <= (customTheme.flag === BINGO_FLAGS.US ? 75 : 90)) {
        const customThemeNumber = customTheme.numbers[randomNumber]
        name = customThemeNumber.n ?? ''
        number = <img className={`absolute random-img-${customTheme.flag}-${CUSTOM_THEME}`} src={customThemeNumber.img}
                      alt="random"/>
    }

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            exit={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className={`background-${customTheme.flag}-${CUSTOM_THEME} custom-theme-to-show-background`}
            style={customTheme?.background ? { backgroundImage: `url(${customTheme.background})`, backgroundSize: '1920px 1080px' } :
                { backgroundImage: 'linear-gradient(to bottom, #3f88de, #b6c9ef)' }}>
            <div className={`scoreboard-${customTheme.flag}`} style={{ position: 'absolute' }}>
                {[...Array(customTheme.rows).keys()].map((_, rowIndex) => {
                    const start = rowIndex * customTheme.cols
                    return (
                        <Row gutter={16} style={{ paddingBottom: 16 }} justify="center" key={rowIndex}>
                            {[...Array(customTheme.cols).keys()].map((_, numIndex) => {
                                const num = start + numIndex + 1
                                return <Col key={num}
                                            style={{ width: customTheme.flag === BINGO_FLAGS.US ? '6.666%' : '10%' }}>
                                    <div className="col-img-container col-img-container-called">
                                        <img className="number-img number-img-called" src={customTheme.numbers[num].img}
                                             alt="number" loading="lazy"
                                             onError={(e) => {
                                                 handleImageError(e.target, customTheme.numbers[num].img)
                                             }}/>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    )
                })}
            </div>
            <div className={`absolute random-container-${customTheme.flag}-${CUSTOM_THEME}`}>
                <RandomNumber
                    randomNumber={randomNumber}
                    isCustomTheme={true}
                    flag={customTheme.flag}
                    theme={null}
                    spotlightType={SPOLIGHT_TYPE.NUMBERS}
                    linePeople={[]}
                    bingoPeople={[]}
                    lineWinners={[]}
                    bingoWinners={[]}
                    number={number}
                />
            </div>

            {
                Boolean(name) &&
                <div className={`random-name random-name-${customTheme.flag}-${CUSTOM_THEME}`}>
                    <div className={`random-name-inner random-name-inner-${customTheme.flag}-${CUSTOM_THEME}`}>
                        {name}
                    </div>
                </div>
            }

            {
                //Se il bingo è americano, stampa le lettere 'BINGO' a sinistra delle file dei numeri
                customTheme.flag === BINGO_FLAGS.US &&
                <div className="bingo-letters-container">
                    {['B', 'I', 'N', 'G', 'O'].map((it, index) => {
                        return (
                            <Row style={{ paddingBottom: 16 }} key={'letter' + index}>
                                <Col>
                                    <div className="bingo-letter-square">
                                        <div className="bingo-letter">{it}</div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    })}
                </div>
            }
        </motion.div>
    )
}