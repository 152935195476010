import bingoAudio1 from '../audio/bingo/bingo_crazy_male.mp3'
import bingoAudio2 from '../audio/bingo/bingo_male.mp3'
import bingoAudio3 from '../audio/bingo/bingo_senior_female.mp3'
import bingoAudio4 from '../audio/bingo/bingo_young_female.mp3'
import bingoAudio5 from '../audio/bingo/bingo_young_male.mp3'
import bingoAudio6 from '../audio/bingo/bingo_6.mp3'
import bingoAudio7 from '../audio/bingo/bingo_7.mp3'
import bingoAudio8 from '../audio/bingo/bingo_8.mp3'
import bingoAudio9 from '../audio/bingo/bingo_9.mp3'
import lineAudio1En from '../audio/line/line_en_libby.mp3'
import lineAudio2En from '../audio/line/line_en_turna.mp3'
import lineAudio1Es from '../audio/line/line_es_javiersalarich.mp3'
import lineAudio2Es from '../audio/line/line_es_silvia.mp3'
import lineAudio1It from '../audio/line/line_it_gian.mp3'
import lineAudio2It from '../audio/line/line_it_turna.mp3'
import popSound from '../audio/pop.mp3'
import balloonPopSound from '../audio/balloon_pop.mp3'
import victorySound from '../audio/victory.mp3'
import drumrollSound from '../audio/drumroll.mp3'
import buySound from '../audio/buy.mp3'

const bingoAudios = [bingoAudio1, bingoAudio2, bingoAudio3, bingoAudio4, bingoAudio5, bingoAudio6, bingoAudio7, bingoAudio8, bingoAudio9]
const lineAudios = {
    en: [lineAudio1En, lineAudio2En],
    es: [lineAudio1Es, lineAudio2Es],
    it: [lineAudio1It, lineAudio2It],
}

export const playAudio = (audioPath) => {
    const audio = new Audio(audioPath)
    audio.load()
    audio.loop = false
    audio.play().catch(error => {
        console.error(error)
    })
}

export const playPopSound = () => {
    playAudio(popSound)
}

export const playBalloonPopSound = () => {
    playAudio(balloonPopSound)
}

export const playVictorySound = () => {
    playBalloonPopSound()
    playAudio(victorySound)
}

export const playBuySound = () => {
    playAudio(buySound)
}

export const playLineAudio = (language) => {
    playAudio(lineAudios[language][Math.floor(Math.random() * lineAudios[language].length)])
}

export const playBingoAudio = () => {
    playAudio(bingoAudios[Math.floor(Math.random() * bingoAudios.length)])
}

let drumrollAudio
export const loopDrumrollSound = () => {
    drumrollAudio = new Audio(drumrollSound)
    drumrollAudio.load()
    drumrollAudio.addEventListener('timeupdate', function () {
        var buffer = .44
        if (this.currentTime > this.duration - buffer) {
            this.currentTime = 0
            this.play()
        }
    })
    drumrollAudio.play().catch(error => {
        console.error(error)
    })
}

export const stopDrumrollSound = () => {
    if (drumrollAudio) {
        drumrollAudio.pause()
        drumrollAudio.currentTime = 0
    }
}
